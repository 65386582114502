import { gql } from '@apollo/client'
import Layout from './Layout'
import QueryWrapper from '../../../utils/QueryWrapper'
import { connect } from 'react-redux'
import { PROJECT_NAME } from '../../../env'
import { ProjectList } from '../../../utils/constants'
import Loader from '../Loader/Loader'
import React from 'react'

let lessonsProjects = ''
const comingSoon = PROJECT_NAME === ProjectList.SJ ? 'comingSoon' : ''

if (PROJECT_NAME === ProjectList.CONTENTPREVIEW) {
  lessonsProjects = `
      highSchool {
        id
        title
        kitKind
        template
        customLink
      }
      middleSchool {
        id
        title
        kitKind
        template
        customLink
      }
      university {
        id
        title
        kitKind
        template
        customLink
      }`
} else {
  lessonsProjects = `
        projects {
        id
        slug
        title
        kitKind
      }
      lessons {
        id
        slug
        title
        kitKind
      }`
}

const GET_INITIAL_DATA = gql`
  query($locale: SiteLocale!, $extensionsLang: SiteLocale!, $kind: String!) {
    modules: allModules(
      locale: $locale
      orderBy: startPosition_ASC
      filter: { kitKind: { matches: { pattern: $kind } }, expansionPacks: { eq: false } }
    ) {
      id
      kitKind
      slug
      title
      cardDescription
     ${lessonsProjects}
    }
    extensions: allModules(
      locale: $extensionsLang
      orderBy: startPosition_ASC
      filter: { kitKind: { matches: { pattern: $kind } }, expansionPacks: { eq: true } }
    ) {
      id
      kitKind
      slug
      title
      ${comingSoon}
      cardDescription
      ${lessonsProjects}
      showOnHomePage
    }
    _site {
      globalSeo {
        siteName
      }
      favicon {
        url
        title
      }
    }
    messages: locale(locale: $locale) {
      commonMore
      commonUnlock
      contactsDesc
      contactsUs
      estimateTime
      footerCopy
      footerTitle
      headerCore
      headerGlossary
      headerModules
      headerDashboard
      knowledge
      nextProject
      nextLesson
      moduleDoProject
      moduleExpansion
      moduleLearnIt
      moduleLesson
      moduleLessons
      modulePart
      moduleProject
      moduleProjects
      projectLevelDifficulty
      search
      skills
      startPart
      step
      teacherDownload
      teacherTips
      videoSupportMessage
      willLearn
      helpPlaceholder
      firstName
      lastName
      emailAddress
      contactCategory
      subject
      helpMessage
      submit
      startExtensionPack
      furtherNotes
    }
  }
`

const mapStateToProps = (state) => {
  return {
    kitsData: state.root.kits.data,
    language: state.root.profile.language,
  }
}

function LayoutContainer(props) {
  if (!props.kitsData.kind) {
    return <Loader />
  }
  let kinds = `${props.kitsData.kind}(?!\\w)`
  const extensions = props.kitsData.extensions
    .map((item) => {
      return `${item}(?!\\w)`
    })
    .join('|')

  if (extensions) {
    kinds += `|${extensions}`
  }
  return QueryWrapper(GET_INITIAL_DATA, Layout, props, {
    kind: kinds,
    language: props.language,
  })
}

const ConnectedLayoutContainer = connect(mapStateToProps)(LayoutContainer)
export default ConnectedLayoutContainer
