import { call, put, takeEvery, fork } from 'redux-saga/effects'
import actions from './actions'
import AuthenticationTypes from './types'
import { PROJECT_NAME, APP_URL } from '../../env'
import { getKits, getKitsByOrganization } from '../../apis/kits'
import { AppAccessible, CLASSROOM_DASHBOARD, ProjectList } from '../../utils/constants'
import CONFIG from '../../configurator'

export function decodeToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const { isPublicContent } = CONFIG[PROJECT_NAME]
export const isStudentKit = PROJECT_NAME === ProjectList.STUDENT_KIT
/**
 * Checks if the user is authenticated. If not, tries
 * to authenticate via the header-footer Auth0 method.
 */
const login = function* login() {
  yield put(actions.loginRequested())
  // value for content-preview project
  if (isPublicContent) {
    yield put(
      actions.getKitsSucceeded({
        kind: AppAccessible[PROJECT_NAME],
        language: 'en',
        role: 'admin',
      }),
    )
  }
  try {
    // get token
    const token = yield call(window.auth.getAccessToken)
    // get profile
    yield put(actions.loginSucceeded(token))
  } catch (error) {
    if (!isPublicContent) {
      if (error.error === 'login_required') {
        return window.auth.login({ href: APP_URL, loginCoppa: process.env.IS_MINOR && 'login-selector' })
      } else {
        yield put(actions.loginFailed(error))
      }
    }
  }
}

// get Profile
const getProfile = function* getProfile({ token }) {
  yield put(actions.getProfileRequested())
  try {
    const decoded = decodeToken(token)
    const isMinor = Boolean(decoded['http://arduino.cc/is_minor'])

    const profile = yield call(window.auth.getProfile)
    return yield put(actions.getProfileSucceeded({ ...profile, isMinor }))
  } catch (error) {
    yield put(actions.getProfileFailed(error))
  }
}

const watchLogin = function* watchLogin() {
  yield takeEvery(AuthenticationTypes.LOGIN, login)
}

const watchLoginSuccess = function* watchLoginSuccess() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, loginSuccess)
}

const loginSuccess = function* loginSuccess(token) {
  yield fork(() => getProfile(token))
}

const watchGetProfile = function* watchGetProfile() {
  yield takeEvery(AuthenticationTypes.GET_PROFILE, loginSuccess)
}

const getKitsSaga = function* getKitsSaga() {
  yield put(actions.getKitsRequested())
  try {
    const kits = yield call(getKits)

    const findCurrentKit = (item) => {
      if (PROJECT_NAME === ProjectList.CTC101) {
        return item.kind === AppAccessible[PROJECT_NAME] || item.kind === 'CTC101SELF'
      }
      return item.kind === AppAccessible[PROJECT_NAME]
    }

    let currentKit = kits?.find(findCurrentKit)

    if (!currentKit) {
      const organizationKits = yield call(getKitsByOrganization)
      currentKit = organizationKits?.kits?.find(findCurrentKit)
    }

    if (!currentKit) {
      window.location.href = CLASSROOM_DASHBOARD
    }

    if (PROJECT_NAME === ProjectList.CTC101) {
      // TODO
      currentKit._kind = currentKit.kind
      currentKit.kind = AppAccessible[PROJECT_NAME]
    }

    yield put(actions.getKitsSucceeded(currentKit))
  } catch (error) {
    yield put(actions.getKitsFailed(error))
  }
}

const watchGetKits = function* watchGetKits() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, getKitsFork)
}

const getKitsFork = function* getKitsFork() {
  if (!isPublicContent) {
    yield fork(getKitsSaga)
  }
}

export default [watchLogin, watchLoginSuccess, watchGetProfile, watchGetKits]
