import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ProjectList, hasExtendedAccess } from '../../../utils/constants'
import { PROJECT_NAME } from '../../../env'
import Modal from '../../ui-common/Modal/Modal'
import { Steps } from 'intro.js-react'
import { getCookie, setCookie } from '../../../utils/cookies'
import { ProfilePopover } from './Popover/ProfilePopover'
import { MarkerTrialPopover } from './Popover/MarkerTrialPopover'
import { IntroModalBody } from './Popover/IntroModalBody'
import 'intro.js/introjs.css'
import '../../../styles/popover.scss'
import CONFIG from '../../../configurator'
import { connect } from 'react-redux'

const options = {
  showBullets: false,
  nextLabel: 'OKAY ',
  doneLabel: 'GOT IT',
  hidePrev: true,
}

const profileStep = {
  intro: <ProfilePopover />,
  element: '.profile-desktop-container .profile-dropdown',
  tooltipClass: 'profile-tooltip',
}

const makerTrialState = {
  intro: <MarkerTrialPopover />,
  element: '.menu-buttons .app-menu-button:nth-child(2)',
}

function IntroPopover({ userRole, isMakerPlan, isMinor }) {
  const [isModalOpen, setIsModalOpen] = useState(Boolean(getCookie('intro-popovers')))
  const [isIntroShowed, setIsIntroShowed] = useState(false)

  const [steps, _setSteps] = useState([])
  const setSteps = (value) => {
    return _setSteps(value)
  }

  const handleModals = async () => {
    setIsModalOpen(true)
    setIsIntroShowed(true)
    setCookie('intro-popovers', 'true')
  }

  useEffect(() => {
    if (PROJECT_NAME === ProjectList.SJ) return
    const func = async () => {
      if (!CONFIG[PROJECT_NAME].updates && !isModalOpen) {
        handleModals()
      }
      const isAuthenticated = await window.auth.isAuthenticated()
      if (PROJECT_NAME === ProjectList.OPLA) {
        if (isAuthenticated) {
          if (!isMakerPlan) {
            return setSteps([makerTrialState, profileStep])
          } else {
            return setSteps([profileStep])
          }
        } else {
          return setSteps([makerTrialState])
        }
      } else {
        if (CONFIG[PROJECT_NAME].isPublicContent) {
          if (isAuthenticated) {
            return setSteps([profileStep])
          }
        }

        if (!(hasExtendedAccess(userRole) && !isMinor)) {
          return setSteps([profileStep])
        }
      }
    }
    setTimeout(func, 3000)
  }, [])

  return (
    <>
      <Modal className="modal--updates" open={!isModalOpen && !!CONFIG[PROJECT_NAME].updates}>
        <IntroModalBody onClose={handleModals} {...CONFIG[PROJECT_NAME].updates} />
      </Modal>
      <Steps enabled={isIntroShowed} steps={steps} initialStep={0} options={options} onExit={() => {}} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isMinor: state.root.profile.data.isMinor,
  }
}
export default connect(mapStateToProps)(IntroPopover)
IntroPopover.propTypes = {
  userRole: PropTypes.string,
  isMakerPlan: PropTypes.bool,
  loaded: PropTypes.bool,
  isMinor: PropTypes.bool,
}
