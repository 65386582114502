import { useEffect } from 'react'
import { APP_NAME, FLOW_ENV } from '../env'
import { getCookie } from '../utils/cookies'
import { useLocation } from 'react-router-dom'

export const useTracking = () => {
  const location = useLocation()

  useEffect(() => {
    window.auth.getProfile().then((profile) => {
      window.dataLayer.push({
        event: 'virtualPageView',
        page: {
          type: 'content',
          title: APP_NAME,
          path: window.location.pathname,
          environment: FLOW_ENV,
          region: 'global',
          brand: 'arduino',
        },
        ...(profile['http://arduino.cc/is_minor'] && { userkind: 'minor' }),
        user: {
          id: profile?.['http://arduino.cc/id'],
          language: getCookie(`${APP_NAME}_language`),
        },
      })
    })
  }, [location])
}
