const English = { id: 'en', value: 'English' }
const Spanish = { id: 'es', value: 'Spanish' }
const German = { id: 'de', value: 'German' }
const Portuguese = { id: 'pt', value: 'Portuguese' }
const Italian = { id: 'it', value: 'Italian' }
const French = { id: 'fr', value: 'French' }
const Croatian = { id: 'hr', value: 'Croatian' }
const Chinese = { id: 'zh', value: 'Chinese' }
const Greek = { id: 'el', value: 'Greek' }
const Thai = { id: 'th', value: 'Thai' }
const Hungarian = { id: 'hu', value: 'Hungarian' }

const CONFIG = {
  'ctc-go': {
    onlyMarkdown: true,
    glossary: true,
    resources: false,
    single: false,
    langs: {
      prod: [English, French, German, Italian, Portuguese, Spanish],
      dev: [English, French, German, Italian, Portuguese, Spanish],
      extensions: new Set(['en', 'es', 'fr']),
    },
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'content-preview': {
    isPublicContent: true,
    glossary: true,
    resources: false,
    single: true,
    langs: {
      prod: [English, French, German, Greek, Italian, Portuguese, Spanish],
      dev: [English, French, German, Greek, Italian, Portuguese, Spanish],
    },
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'explore-iot': {
    glossary: true,
    resources: false,
    single: true,
    langs: {
      prod: [English, German, Italian, Portuguese, Spanish],
      dev: [English, German, Greek, Italian, Portuguese, Spanish],
    },
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
    updates: {
      title: 'New updates!',
      description: `
        <p>It's now even easier to get started with the Internet of Things:</p>
        <ol>
          <li> - More teacher support with new teacher tips</li>
          <li> - An improved Teacher Guide</li>
          <li> - The IoT Cloud is now easier to navigate</li>
          <li> - Updated MKR IoT Carrier libraries and content sketches</li>
          <li> - New how-to guide to using the MKR IoT Carrier</li>
        </ol>
        `,
    },
  },
  'starter-kit': {
    onlyMarkdown: true,
    glossary: false,
    resources: true,
    single: true,
    langs: {
      prod: [English, Chinese, Croatian, French, German, Italian, Portuguese, Spanish, Thai],
      dev: [English, Chinese, Croatian, French, German, Italian, Portuguese, Spanish, Thai],
    },
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
  },
  'science-kit': {
    onlyMarkdown: true,
    glossary: true,
    resources: false,
    oneList: true,
    langs: {
      prod: [English, German, Italian, Portuguese, Spanish, Hungarian],
      dev: [English, German, Italian, Portuguese, Spanish, Hungarian],
      extensions: new Set(['en', 'de', 'it', 'pt', 'es', 'hu']),
    },
    showTipsIcon: true,
    levelOfDifficulty: false,
    cardNumbering: true,
    allowGuideToAll: true,
  },
  'greenhouse-kit': {
    onlyMarkdown: true,
    glossary: true,
    resources: false,
    oneList: true,
    langs: {
      prod: [English, Italian],
      dev: [English, Italian],
      extensions: new Set(['en', 'it']),
    },
    showTipsIcon: true,
    levelOfDifficulty: false,
    cardNumbering: true,
    allowGuideToAll: true,
  },
  braccio: {
    onlyMarkdown: true,
    glossary: false,
    resources: false,
    single: true,
    langs: {
      prod: [English],
      dev: [English],
    },
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
    allowGuideToAll: true,
  },
  'student-kit': {
    glossary: false,
    resources: true,
    single: true,
    langs: {
      prod: [English, Chinese, Croatian, French, German, Italian, Portuguese, Spanish, Thai],
      dev: [English, Chinese, Croatian, French, German, Italian, Portuguese, Spanish, Thai],
    },
    onlyMarkdown: true,
    showTipsIcon: true,
    levelOfDifficulty: true,
    cardNumbering: true,
    allowGuideToAll: true,
  },
  'engineering-kit': {
    glossary: false,
    resources: false,
    single: true,
    langs: {
      prod: [English, Spanish],
      dev: [English, Spanish],
    },
    showTipsIcon: false,
    levelOfDifficulty: false,
    cardNumbering: false,
  },
  'science-journal': {
    isPublicContent: true,
    gibridNavigation: true,
    glossary: true,
    resources: false,
    single: false,
    langs: {
      prod: [English],
      dev: [English],
    },
    showTipsIcon: false,
    levelOfDifficulty: false,
    cardNumbering: false,
  },
  opla: {
    isPublicContent: true,
    gibridNavigation: true,
    lessonsList: true,
    glossary: false,
    resources: false,
    single: false,
    langs: {
      prod: [English, French, German, Italian, Spanish],
      dev: [English, French, German, Italian, Spanish],
      extensions: new Set(['en', 'fr', 'de', 'it', 'es']),
    },
    showTipsIcon: false,
    levelOfDifficulty: false,
    cardNumbering: false,
  },
  'sensor-kit': {
    isPublicContent: true,
    gibridNavigation: true,
    lessonsList: true,
    glossary: false,
    resources: false,
    single: false,
    langs: {
      prod: [English, Chinese, Greek, Spanish],
      dev: [English, Chinese, Greek, Spanish],
      extensions: new Set(['en', 'zh', 'el', 'es']),
    },
    showTipsIcon: false,

    levelOfDifficulty: false,
    cardNumbering: false,
  },
  ctc101: {
    glossary: false,
    resources: true,
    single: false,
    langs: {
      prod: [English, Italian, Spanish],
      dev: [English, Italian, Spanish],
    },
    onlyMarkdown: true,
    showTipsIcon: false,
    levelOfDifficulty: true,
    cardNumbering: true,
    updates: {
      title: 'Welcome to the updated CTC 101 platform!',
      description: `
      <p>CTC 101 now has a completely new, modern, and easy-to-navigate interface:</p>
      <ul>
        <li> - Discover the updated lessons and projects via the Modules</li>
        <li> - Find educator training and webinar booking under Educators</li>
        <li> - Explore additional guides and tips in Resources</li>
        <li> - Enrol students and other educators via the Dashboard</li>
      </ul>
      <p><b>Note: </b> The quizzes are currently under maintenance and can not be accessed.</p>
      <p>If you have any questions, you can check our <a href="https://support.arduino.cc/hc/en-us/sections/360003249039-CTC-101-and-CTC-UNO" target="_blank">Help Centre</a>, or <a href="https://www.arduino.cc/en/contact-us/" target="_blank">contact the support team</a>.</p>
        `,
    },
  },
}

export default CONFIG
