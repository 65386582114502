/* eslint-disable */

import { ProjectList } from './utils/constants'

export const APP_NAME = process.env.APP_NAME
export const BASE_URL = process.env.BASE_URL
export const APP_URL = process.env.APP_URL
export const AUTH_URL = process.env.AUTH_URL
export const API_URL = process.env.API_URL
export const DATOCMS_API = process.env.DATOCMS_API
export const DATOCMS_API_TOKEN = process.env.DATOCMS_API_TOKEN
export const DATOCMS_GQL_API = process.env.DATOCMS_GQL_API
export const FLOW_ENV = process.env.FLOW_ENV
export const CREATE_URL = process.env.CREATE_URL
export const PROJECT_NAME = process.env.PROJECT || ProjectList.CTC_GO
export const DIGITAL_STORE_URL = process.env.DIGITAL_STORE_URL
