import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { APP_NAME, PROJECT_NAME } from '../../../env'
import { rootActions } from '../../../store/root'
import { isZenWidgetShow } from '../../../utils/constants'
import loadZen from '../../../utils/zen-button'
import IntroPopover from './IntroPopover'
import HF from './HF'
import { getSubscriptions } from '../../../apis/kits'

const Layout = (props) => {
  const { _site = { title: '', favicon: { url: '' } }, messages, modules, extensions } = props.data
  const [isMakerPlan, setIsMakerPlan] = useState(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded) return
    const func = async () => {
      const isAuthenticated = await window.auth.isAuthenticated()

      if (isAuthenticated) {
        const { plans = [] } = await getSubscriptions()
        const _isMakerPlan = plans.some((element) => ['create-maker-trial', 'create-maker'].includes(element))
        await setIsMakerPlan(_isMakerPlan)
        await setLoaded(true)
      }
      await setLoaded(true)
    }
    func()

    if (!props.isMinor) {
      loadZen()
    }
  }, [])

  return (
    <IntlProvider locale={props.language} messages={messages}>
      <HF
        onLanguageChange={props.onLanguageChange}
        userRole={props.userRole}
        products={{ modules, extensions }}
        kitsData={props.kitsData}
        selectedLanguage={props.selectedLanguage}
        isMakerPlan={isMakerPlan}
        loaded={loaded}
      />
      {isZenWidgetShow(props.userRole) && (
        <style
          dangerouslySetInnerHTML={{
            __html: 'iframe#launcher,.zen-button {display: flex;}',
          }}
        />
      )}
      <div className={`app ${PROJECT_NAME}-application`}>
        <Helmet>
          <title>{_site.title || APP_NAME}</title>
          {_site.favicon && <link rel="shortcut icon" href={_site.favicon.url} type="image/png" />}
          <meta
            name="description"
            content="Arduino Education is focused on creating the next generation of STEAM programs that progress students through middle school, high school, and university and help them thrive."
          />
        </Helmet>
        {loaded && <IntroPopover userRole={props.userRole} isMakerPlan={isMakerPlan} />}
        <div className="container wrap">{props.children}</div>
      </div>
      {props.lightbox && window.innerWidth > 980 && (
        <RemoveScroll enabled={true} removeScrollBar={false}>
          <div id="lightbox" className="lightbox">
            <div className="step-block-close-fullscreen" role="button" onClick={() => props.triggerLightbox(null)}>
              <i className="icon-fullscreen_close" />
            </div>
            {props.lightbox}
          </div>
        </RemoveScroll>
      )}
    </IntlProvider>
  )
}

Layout.propTypes = {
  language: PropTypes.string,
  isFirstVisit: PropTypes.bool,
  userRole: PropTypes.string,
  role: PropTypes.string,
  kitsData: PropTypes.object,
  data: PropTypes.object,
  locale: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
  onLanguageChange: PropTypes.func,
  triggerLightbox: PropTypes.func,
  lightbox: PropTypes.object,
  selectedLanguage: PropTypes.string,
  isMinor: PropTypes.bool,
}

const mapDispatchToProps = {
  onLanguageChange: rootActions.onLanguageChange,
  triggerLightbox: rootActions.triggerLightbox,
}

const mapStateToProps = (state) => {
  return {
    lightbox: state.root.lightbox,
    isFirstVisit: state.root.profile.data.isFirstVisit,
    userRole: state.root.kits.data.role,
    kitsData: state.root.kits.data,
    selectedLanguage: state.root.profile.language,
    isMinor: state.root.profile.data.isMinor,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Layout))
